import { useEffect } from 'react';

import { useContentPageContext } from '@netfront/ekardo-content-library';
import { useAuthentication } from '@netfront/gelada-identity-library';

import { AdvertisementApp } from '../components/AdvertisementApp';
import { Affiliates } from '../components/Affiliates';
import { HelpButton } from '../components/HelpButton';
import { Link } from '../components/Link';
import { PageLayout } from '../components/PageLayout';
import { FeaturesSlider, HomeQuoteSlider, IntroSlider } from '../components/Sliders';

const HomePage = () => {
  const { isAuthenticated } = useAuthentication();
  const { dispatch } = useContentPageContext();

  const accessTheProgramUrl = isAuthenticated ? '/dashboard' : '/login';

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    dispatch({
      type: 'unloadContentPage',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <PageLayout hasPrivateLayout={false} title="Keeping Friends and Family in Mind">
      <section className="flex align-center bg-hero">
        <div className="container-md w-full flex flex-col  md:flex-row">
          <div className="w-full sm:w-quarter py-16">
            <div className="flex justify-center mb-6 md:justify-end">
              <HelpButton />
            </div>
            <div className="flex flex-col align-center radius bg-white-opacity p-8 md:my-16 md:inline-flex md:align-start md:p-16 md:p-8">
              <h1 className="color-blue-900 font-header mb-4 md:size-16">
                Carer
                <span className="color-accent h1 md:size-16">Well</span>
              </h1>
              <h2 className="color-blue-900 font-body h5 mb-8 text-center">Keeping Friends and Family in Mind: Brain Cancer Support</h2>
              <div className="flex align-center">
                <Link className="mr-4" href={accessTheProgramUrl} theme="default">
                  Access the program
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-accent-400 py-12 md:py-16">
        <div className="container-md">
          <IntroSlider />
          <p className="text-center mt-8 size-3 mb-0 color-green-900">
            Piil, K., et al., Daily Life Experiences of Patients With a High-Grade Glioma and Their Caregivers: A Longitudinal Exploration
            of Rehabilitation and Supportive Care Needs. J Neurosci Nurs, 2015; Halkett, G.K., et al., Distress and psychological morbidity
            do not reduce over time in carers of patients with high-grade glioma. Support Care Cancer, 2017.; Philip, J., et al., Mapping
            the nature of distress raised by patients with high-grade glioma and their family caregivers: a descriptive longitudinal study.
            Neurooncol Pract, 2020; Reinert, C., et al., Single-institution cross-sectional study to evaluate need for information and need
            for referral to psychooncology care in association with depression in brain tumor patients and their family caregivers. BMC
            Psychol, 2020. (Accessed September, 2020).
          </p>
        </div>
      </section>
      <section className="container-md md:py-32 py-12">
        <div className="flex flex-col md:flex-row -mx-8">
          <div className="md:w-half w-full md:mb-0 p-8">
            <span className="block color-accent h4 mb-4 weight-800">About</span>
            <h2 className="color-blue-900 h2 mb-8">
              Learn more about this <br /> Mark Hughes Foundation Funded Project
            </h2>
            <p>
              “When I was first diagnosed with brain cancer my family were always there for me,” says MHF co-Founder Mark Hughes. “But who
              was there for them?” Mark asks.
            </p>
            <p>
              When someone is diagnosed with brain cancer, it will have a big impact on the people closest to them. The goal of this project
              was to ensure that friends and family members have the resources and support they need. We spoke with the Australian brain
              cancer community to understand their experiences and what support they would like to see online.
            </p>
            <p className="mb-10">
              CarerWell is an online website and program for brain cancer supporters, no matter where they are on their journey. Access it
              anytime, anywhere in Australia.
            </p>
            <div className="flex">
              <Link className="mr-4" href="/about-us" theme="default">
                Learn more
              </Link>

              <Link href={accessTheProgramUrl} theme="default">
                Access the program
              </Link>
            </div>
          </div>
          <div className="md:w-half w-full flex flex-col align-center justify-center p-8">
            <div className="c-video-wrapper mb-8">
              <iframe
                allow="autoplay; fullscreen; picture-in-picture"
                frameBorder="0"
                height="360"
                loading="lazy"
                src="https://player.vimeo.com/video/625040045?h=102db9273f&amp;title=0&amp;byline=0&amp;portrait=0"
                title="Mark Hughes video"
                width="640"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12">
        <div className="container-md">
          <HomeQuoteSlider />
        </div>
      </section>
      <section className="py-12 md:py-32">
        <div className="container-md">
          <FeaturesSlider />
        </div>
      </section>
      <section className="container-md md:pt-32 py-12">
        <div className="flex flex-col md:flex-row">
          <div className="align-center flex flex-col justify-center md:w-half text-center w-full">
            <h3 className="color-blue-900 font-header h1 mb-4 md:size-16">
              Carer<span className="color-accent h1 md:size-16">Well</span>
            </h3>
            <h3 className="color-blue-900 font-body h5 mb-8 mb-12 md:mb-0">Keeping Friends and Family in Mind</h3>
          </div>
          <div className="md:text-left md:w-half text-center w-full">
            <img alt="Cartoon of user engaging with the CarerWell program" loading="lazy" src="/images/home-graphic.svg" />
          </div>
        </div>
      </section>

      <AdvertisementApp />

      <Affiliates isAddYMargin />
    </PageLayout>
  );
};

// eslint-disable-next-line import/no-default-export
export default HomePage;
