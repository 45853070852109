import React from 'react';

import { Link } from '../Link';

const AdvertisementApp = () => {
  return (
    <section className="radius bg-white px-12 container-md md:pt-24 mb-12 py-12">
      <div className="flex flex-col md:flex-row justify-center">
        <div className="flex-col justify-center md:w-half w-full">
          <div className="flex md:px-6 py-8">
            <img alt="Advertisement phone" className="max-w-16 h-16" loading="lazy" src="/android-chrome-192x192.png" />
            <h3 className="color-blue-900 font-header h1 mb-4 md:size-12 px-3">
              Carer<span className="color-accent h1 md:size-12">Well</span>
            </h3>
          </div>
          <div>
            <div className="md:px-20">
              <p className="color-blue-900 font-body h5 mb-2">The CarerWell app is now available on the</p>
              <p className="color-blue-900 font-body h5 mb-6">Apple and Google Play stores.</p>
            </div>
            <p className="color-blue-900 font-body h5 md:px-20">Click the links below to download.</p>
          </div>
          <div className="py-4 md:px-16 justify-center">
            <div className="border border-accent-300" />
            <div className="flex flex-gap-4 py-8">
              <Link
                href="https://play.google.com/store/apps/details?id=com.netfront.carerwell&hl=en_AU&gl=US"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img alt="Google play" className="" loading="lazy" src="/images/google-play.png" />
              </Link>
              <Link href="https://apps.apple.com/in/app/carerwell/id1599153796" rel="noopener noreferrer" target="_blank">
                <img alt="App store" loading="lazy" src="/images/app-store.png" />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex justify-center radius md:py-12 lg:py-0 md:w-half w-full">
          <img alt="Advertisement phone" loading="lazy" src="/images/advertisement-phone.webp" />
        </div>
      </div>
    </section>
  );
};

export { AdvertisementApp };
